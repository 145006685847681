/*!*******************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[3].use[1]!../../node_modules/postcss-loader/src/index.js??ruleSet[1].rules[0].oneOf[3].use[2]!./global.css ***!
  \*******************************************************************************************************************************************************************************************/
/*
 This file is for any global CSS that we want in our app. Most of the styling
 for the app should use emotion.
 */

/* Reset root font-size for App */
:root {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'PayPalOpen-Regular', sans-serif;
}

/* display list style in product page*/
#description ul {
  list-style-type: disc;
}

.contents.mainContent {
  max-width: none;
  padding-bottom: 0;
}

.top-left {
  position: relative;
  z-index: 1;
}

.top-left:after,
.top-left:before {
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid #ffffff;
  top: -24px;
  content: '';
  left: 43px;
  position: absolute;
}

/* Styling for second triangle (border) */

.top-left:before {
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid #0070ba;
  top: -26px;
  left: 43px;
}

.carousel__dot--selected {
  background-color: #0070ba !important;
}

div[id*='globalnav-header'][data-nav-version='rebrand']
  ~ div
  div[class*='vx_mainContent'] {
  @media (min-width: 1024px) {
    padding-top: 89px;
  }
}

div[id*='globalnav-header'][data-nav-version='legacy']
  ~ div
  div[class*='vx_mainContent'] {
  @media (min-width: 1024px) {
    padding-top: 73px;
  }
}

@media (max-width: 1152px) {
  .vx_mainContent.mainContent {
    padding-top: 56.5px;
  }
}

div[class*='cw_navbar_drawer'] ~ div div[class*='vx_mainContent'] {
  @media (min-width: 820px) {
    padding: 0 !important;
  }
}

#toggleNavigation {
  display: none;
}

/*
 * Fixes issues where vxpattern-lib adds incorrect styling to consumer
 * header/footer for logged in user.
 */
a.vx_globalNav-link_logout,
a.vx_globalNav-link_logout:visited {
  border-left: none;
  border-right: none;
  border-top: none;
}
.vx_globalFooter {
  margin-top: 0 !important;
}
@media (max-width: 769px) {
  .vx_globalNav-list a,
  .vx_globalNav-list a:visited {
    padding-left: 10% !important;
  }
}

/* style for carousel slider */
.sliderStyle {
  height: 324px;
}
@media (max-width: 768px) {
  .sliderStyle {
    height: 410px;
  }
}

/* Override logged-in nav fixed positioning */
#gifts-new .vx_globalNav-main,
#gifts-new .cw_navbar__mainnav {
  position: static;
}
#gifts-new .cw_navbar {
  height: auto;
}

/* Override agressive library normalizations */
#gifts-new .ppdg-wrapper p + p {
  padding-top: 0;
  margin-bottom: 0;
}

#gifts-new .ppdg-wrapper a:not(.button),
#gifts-new .ppdg-wrapper a:not(.button):visited {
  text-align: inherit;
}

#gifts-new .ppdg-wrapper a:not(.button),
#gifts-new .ppdg-wrapper a:not(.button):hover,
#gifts-new .ppdg-wrapper a:not(.button):visited {
  color: inherit;
}

#gifts-new .ppdg-wrapper a:hover {
  text-decoration: none;
}

#gifts-new .vx_has-spinner-large {
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
}

/* Override pp-footer text color for a11y */
.global-footer .footer-secondary,
.global-footer .footer-tertiary,
.global-footer .footer-secondary a,
.global-footer .footer-tertiary a {
  color: #687173;
}

/*!******************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[3].use[1]!../../node_modules/postcss-loader/src/index.js??ruleSet[1].rules[0].oneOf[3].use[2]!./screens/shared/calendar/style.css ***!
  \******************************************************************************************************************************************************************************************************************/
/* DayPicker styles */

.DayPicker {
  display: inline-block;
}

.DayPicker-wrapper {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 1rem;
  -ms-flex-direction: row;
      flex-direction: row;
}

.DayPicker-Months {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  border: solid 1px #0070ba;
  border-radius: 5px;
}

.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 1rem;
  margin-top: 1rem;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1.5rem;
  margin-top: 2px;
  color: #8b9898;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  padding: 0 0.5rem;
  display: table-caption;
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: 20px;
  line-height: 1.25;
  color: #0070ba;
}

.DayPicker-Caption > div {
  font-size: 1.15rem;
  font-weight: 400;
}

.DayPicker-Weekdays {
  margin-top: 1rem;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem;
  font-size: 0.875em;
  text-align: center;
  color: #8b9898;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.75em;
  cursor: pointer;
  color: #8b9898;
  border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5rem;
}

.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  color: #4a90e2;
  font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  color: #f0f8ff;
  background-color: #4a90e2;
  border-radius: 100%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
  border-radius: 50%;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  left: 0;
  z-index: 1;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}


/*# sourceMappingURL=main.css.map*/