/*
 This file is for any global CSS that we want in our app. Most of the styling
 for the app should use emotion.
 */

/* Reset root font-size for App */
:root {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'PayPalOpen-Regular', sans-serif;
}

/* display list style in product page*/
#description ul {
  list-style-type: disc;
}

.contents.mainContent {
  max-width: none;
  padding-bottom: 0;
}

.top-left {
  position: relative;
  z-index: 1;
}

.top-left:after,
.top-left:before {
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid #ffffff;
  top: -24px;
  content: '';
  left: 43px;
  position: absolute;
}

/* Styling for second triangle (border) */

.top-left:before {
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid #0070ba;
  top: -26px;
  left: 43px;
}

.carousel__dot--selected {
  background-color: #0070ba !important;
}

div[id*='globalnav-header'][data-nav-version='rebrand']
  ~ div
  div[class*='vx_mainContent'] {
  @media (min-width: 1024px) {
    padding-top: 89px;
  }
}

div[id*='globalnav-header'][data-nav-version='legacy']
  ~ div
  div[class*='vx_mainContent'] {
  @media (min-width: 1024px) {
    padding-top: 73px;
  }
}

@media (max-width: 1152px) {
  .vx_mainContent.mainContent {
    padding-top: 56.5px;
  }
}

div[class*='cw_navbar_drawer'] ~ div div[class*='vx_mainContent'] {
  @media (min-width: 820px) {
    padding: 0 !important;
  }
}

#toggleNavigation {
  display: none;
}

/*
 * Fixes issues where vxpattern-lib adds incorrect styling to consumer
 * header/footer for logged in user.
 */
a.vx_globalNav-link_logout,
a.vx_globalNav-link_logout:visited {
  border-left: none;
  border-right: none;
  border-top: none;
}
.vx_globalFooter {
  margin-top: 0 !important;
}
@media (max-width: 769px) {
  .vx_globalNav-list a,
  .vx_globalNav-list a:visited {
    padding-left: 10% !important;
  }
}

/* style for carousel slider */
.sliderStyle {
  height: 324px;
}
@media (max-width: 768px) {
  .sliderStyle {
    height: 410px;
  }
}

/* Override logged-in nav fixed positioning */
#gifts-new .vx_globalNav-main,
#gifts-new .cw_navbar__mainnav {
  position: static;
}
#gifts-new .cw_navbar {
  height: auto;
}

/* Override agressive library normalizations */
#gifts-new .ppdg-wrapper p + p {
  padding-top: 0;
  margin-bottom: 0;
}

#gifts-new .ppdg-wrapper a:not(.button),
#gifts-new .ppdg-wrapper a:not(.button):visited {
  text-align: inherit;
}

#gifts-new .ppdg-wrapper a:not(.button),
#gifts-new .ppdg-wrapper a:not(.button):hover,
#gifts-new .ppdg-wrapper a:not(.button):visited {
  color: inherit;
}

#gifts-new .ppdg-wrapper a:hover {
  text-decoration: none;
}

#gifts-new .vx_has-spinner-large {
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
}

/* Override pp-footer text color for a11y */
.global-footer .footer-secondary,
.global-footer .footer-tertiary,
.global-footer .footer-secondary a,
.global-footer .footer-tertiary a {
  color: #687173;
}
